// Global production variables for SKY Girls projects
// Edit this file to customize project to your server

let config = {
  server: 'https://api.skygirlske.com',
  fileserver: 'https://api.skygirlske.com/img',
  db: {
    host: 'sky-db-do-user-3836106-0.a.db.ondigitalocean.com',
    user: 'gatsby',
    password: 'he2qv5h',
    database: 'sky',
    port: 25060,
charset : 'utf8mb4'
  },
  GoogleAnalyticsID: 'UA-175510088-1',
  FacebookPixelID: '980615615693767',
};


exports.config = config;
